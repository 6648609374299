<template>
  <aside style="width: 12%" v-if="menus">
    <!--    <el-menu-->
    <!--      class="el-menu-vertical-demo"-->
    <!--      :text-color="'#E5E5E5'"-->
    <!--      :background-color="'#ffffff'"-->
    <!--      :default-active="String(0)"-->
    <!--    >-->
    <!--      <el-menu-item-->
    <!--        :index="String(menuIdx)"-->
    <!--        v-for="(menu, menuIdx) in menus"-->
    <!--        :key="menu.label"-->
    <!--        @click="chooseTag(menu)"-->
    <!--      >-->
    <!--        <div slot="title" class="menu-item">-->
    <!--          <span class="menu-title">{{ menu.label }}</span>-->
    <!--          <span class="menu-value">{{ menu.value }}</span>-->
    <!--        </div>-->
    <!--      </el-menu-item>-->
    <!--    </el-menu>-->
    <div style="margin-top: 20px"></div>
    <div v-for="(menu, menuIdx) in menus" :key="menuIdx" :class="['tag-item',menu === currentMenu ? 'tag-item-selected' : '']" @click="chooseTag(menu)">
      <span>{{ menu.label }}</span>
    </div>

  </aside>
</template>

<script>
import { getDictDetail } from "@/apis/dict";

export default {
  props: {
    scene: {
      type: String,
      default: () => "公司"
    }
  },
  data() {
    return {
      menus: null,
      currentMenu: null
    };
  },
  methods: {
    chooseTag(menu) {
      this.currentMenu = menu;
      this.$emit("getDataFromTagList", this.getCurrentMenu());
    },
    getCurrentMenu() {
      return this.currentMenu?.label ? this.currentMenu?.label : "";
    },
    getData() {
      if (!this.scene || this.scene === "_") {
        return;
      }
      // getTagList(this.scene).then((res) => {
      //   if (res.data && res.data[0]) {
      //     this.menus = res.data.map((data) => {
      //       return {
      //         label: data,
      //       };
      //     });
      //     this.chooseTag(this.menus[0]);
      //   } else {
      //     this.menus = null;
      //     this.chooseTag("");
      //   }
      // });
      getDictDetail("home_tags").then((res) => {
        if (res.data && res.data[0]) {
          this.menus = res.data.map((data) => {
            return {
              label: data.text
            };
          });
          this.chooseTag(this.menus[0]);
        } else {
          this.menus = null;
          this.chooseTag("");
        }
      });
    }
  },
  watch: {
    scene() {
      this.getData();
    }
  },
  mounted() {
    this.getData();
  }
};
</script>
<style lang="scss" scoped>
aside {
  height: calc(100vh - 88px);
  background: rgba(255, 255, 255, 1);
  padding-left: 27px;
  padding-right: 27px;

  .tag-item {
    width: 100%;
    height: 66px;
    text-align: center;
    font-size: 22px;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #333333;
    line-height: 66px;
    background-color: transparent;
    cursor: pointer;

  }

  .tag-item-selected{
    background-color: rgba(0,82,254,0.08);
    border-radius: 11px;
    font-weight: normal;
    color: #0052FE;
  }
}
</style>
