<!-- 简历辅导 -->
<template>
  <el-container>
    <Menus
      :scene="scene"
      @getDataFromTagList="getDataFromTagList"
      ref="menuRef"
    />
    <el-main class="offer-talk_main content-center">
      <div class="offertalk-resume">
        <Search :callback="searchHandle" v-if="scene && scene !== '_' && isNotModuleDetail"></Search>
        <div>
          <router-view :key="$route.fullPath" />
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import Search from "@/components/Search.vue";
import Menus from "@/components/layous/Menus.vue";

export default {
  name: "ModuleIndex",
  data() {
    return {
      scene: "",
      tag: "",
      keyword: "",
      once: true,
      isNotModuleDetail:false
    };
  },
  components: {
    Search,
    Menus
  },
  mounted() {
    this.scene = this.$route.params.scene;
    // 如果携带了albumId表明要直接访问文章详情页面 并且是第一次直接进入 就不执行getDataFromTagList/searchHandle
    if (!this.$route.params.albumId) {
      this.once = false;
    }
  },
  updated() {
    if (this.scene !== this.$route.params.scene) {
      this.scene = this.$route.params.scene;
    }
    this.isNotModuleDetail = this.$route.params.albumId <= 0 || this.$route.params.albumId === undefined
  },
  methods: {
    getDataFromTagList(menu) {
      if (this.once) {
        this.once = false;
        return false;
      }
      this.tag = menu;
      this.$router.push({
        path: "/module/list/" + this.scene,
        query: {
          tag: menu
        }
      });
    },
    searchHandle(input) {
      if (this.once) {
        this.once = false;
        return false;
      }
      this.keyword = input;
      this.$router.push({
        path: "/module/list/" + this.scene,
        query: {
          tag: this.tag,
          keyword: this.keyword
        }
      });
    }
  }
};
</script>
<style lang="scss">
.offertalk-resume {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 853px;
  align-self: center;
}

.content-center {
  background-color: #fff;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  align-self: center;
}
</style>
