<!-- 搜索 -->
<template>
  <div class="search">
    <div class="margin-top-11 editor">
      <img src="https://res.offertalks.cn/miniapp/search-icon.png" class="icon"/>
      <input @change="enterSearch" v-model="input"
             class="input"
             prefix-icon="el-icon-search"
             placeholder="请输入搜索词" />
    </div>
  </div>
</template>

<script>
export default {
  name: "search",
  props: {
    callback: {
      type: Function,
      default: () => {
      }
    }
  },
  data() {
    return {
      input: "",
      time: null,
      watchPath: null
    };
  },
  created() {
    // $route.params.scene
    // $route.path
    this.watchPath = this.$watch("$route.params.scene", (n, o) => {
      if (n != o) {
        this.input = "";
      }
    });
  },
  methods: {
    enterSearch() {
      if (this.time) {
        clearTimeout(this.time);
        this.time = null;
      }
      this.time = setTimeout(() => {
        this.callback(this.input);
      }, 500);
    }
  }
};

</script>
<style lang="scss" scoped>
.search {
  box-sizing: border-box;
  width: 100%;
  padding: 15px 15px;
  background-color: #fff;

  .margin-top-11 {
    margin-top: 11px;
  }

  .editor {
    height: 55px;
    background: #F6F6F6;
    border-radius: 27px;
    border: 1px solid #EBEBEB;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;

    .icon{
      width: 27px;
      height: 27px;
    }
    .input{
      height: 100%;
      margin-left: 10px;
      margin-right: 20px;
      background: transparent;
      border: none;
      width: 100%;
      padding-left: 10px;
      outline: none;


      font-size: 19px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 27px;
    }
  }
}

</style>